<template>
    <fs-wrapper
        v-if="$route.meta.topRow"
        class="!flex-row !p-0 items-center min-h-[4rem] rounded-none dark:!bg-zinc-950 dark:!bg-opacity-20"
    >
        <div
            class="
                bg-[center_bottom] bg-no-repeat h-11 p-0 min-w-[100px] mr-8
                bg-[url(https://cdn.xmatik.ca/portal/img/logo/fs-black.svg)]
                dark:bg-[url('https://cdn.xmatik.ca/portal/img/logo/fs-white.svg')]
            "
        />
        <!-- desktop menu -->
        <div class="hidden md:flex flex-row items-center flex-1">
            <fs-button
                :type="currentPage === 'policy-search' ? '' : 'text'"
                :color="currentPage === 'policy-search' ? 'blue' : 'black'"
                icon="search"
                class="mr-4"
                @click="$router.push(`/policy-search`);"
            >
                Policy Search
            </fs-button>
            <fs-button
                :type="currentPage === 'users' ? '' : 'text'"
                :color="currentPage === 'users' ? 'blue' : 'black'"
                icon="people"
                class="mr-4"
                @click="$router.push(`/users`);"
            >
                Users
            </fs-button>
            <fs-button
                :type="currentPage === 'geo-reports' ? '' : 'text'"
                :color="currentPage === 'geo-reports' ? 'blue' : 'black'"
                icon="pie_chart"
                class="mr-4"
                @click="$router.push(`/geo-reports`);"
            >
                Geo Reports
            </fs-button>
            <fs-button
                v-if="$store.getters.isGlobalScope"
                :type="currentPage === 'policy' ? '' : 'text'"
                :color="currentPage === 'policy' ? 'blue' : 'black'"
                icon="policy"
                class="mr-4"
                @click="$router.push(`/policy`);"
            >
                Policy ✨
            </fs-button>
            <div class="flex-1" />
            <!-- profile -->
            <fs-button
                v-if="!$store.getters.isDriverUser"
                href="/profile/preferences"
                type="text"
                color="black"
                icon="person"
                class="mr-2"
            />

            <!-- logout -->
            <fs-button type="text" color="black" icon="logout" class="mr-2" @click="logout" />
        </div>

        <!-- mobile menu -->
        <div class="flex md:hidden flex-row flex-1">
            <div class="flex-1" />
            <!-- menu trigger -->
            <fs-button
                type="text"
                color="black"
                icon="menu"
                class="mr-2"
                @click="() => {
                    show = !show;
                    if (show) {
                        disableScroll();
                    } else {
                        enableScroll();
                    }
                }"
            />
        </div>
        <!-- menu content -->
        <fs-wrapper
            size="x-small"
            class="
                md:hidden fixed left-0 right-0 top-16 bottom-0 z-[999999]
                backdrop-blur-lg transition-all ease-in-out origin-top mt-2
            "
            :class="{
                'scale-y-100 opacity-1': show,
                'scale-y-0 opacity-0': !show,
            }"
        >

            <!-- admin page -->
            <fs-button
                :color="currentPage === 'policy-search' ? 'blue' : 'gray'"
                icon="search"
                class="mb-4"
                @click="
                    show = false;
                    $router.push(`/policy-search`);
                "
            >
                Policy Search
            </fs-button>
            <fs-button
                :color="currentPage === 'users' ? 'blue' : 'gray'"
                icon="people"
                class="mb-4"
                @click="
                    show = false;
                    $router.push(`/users`);
                "
            >
                Users
            </fs-button>
            <fs-button
                :color="currentPage === 'geo-reports' ? 'blue' : 'gray'"
                icon="pie_chart"
                @click="
                    show = false;
                    $router.push(`/geo-reports`);
                "
            >
                Geo Reports
            </fs-button>

            <div class="flex-1" />

            <!-- profile -->
            <fs-button v-if="!$store.getters.isDriverUser" href="/profile/preferences" color="gray" icon="person"
                class="mb-2" @click="show = false">
                Profile
            </fs-button>

            <!-- logout -->
            <fs-button
                color="gray"
                icon="logout"
                @click="() => {
                    show = false;
                    logout();
                }"
            >
                Logout
            </fs-button>
        </fs-wrapper>
    </fs-wrapper>
</template>

<script>
import {api} from '$lib';

const keys = {37: 1, 38: 1, 39: 1, 40: 1};
const preventDefault = (e) => e.preventDefault();
const preventDefaultForScrollKeys = (e) => {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
};
const wheelOpt = {passive: false};
const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

export default {
  data() {
    return {
      show: false,
    };
  },
  computed: {
    currentPage() {
      return this.$route.name?.toLowerCase();
    },
  },
  methods: {
    async logout() {
      await api.auth.logout();
      await this.$store.dispatch('checkAuthenticated');
    },
    disableScroll() {
      window.addEventListener('DOMMouseScroll', preventDefault, false);
      window.addEventListener(wheelEvent, preventDefault, wheelOpt);
      window.addEventListener('touchmove', preventDefault, wheelOpt);
      window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    },
    enableScroll() {
      window.removeEventListener('DOMMouseScroll', preventDefault, false);
      window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
      window.removeEventListener('touchmove', preventDefault, wheelOpt);
      window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    },
  },
};
</script>
